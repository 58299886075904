import {AppState} from '../state';
import {createSelector} from '@ngrx/store';
import {RefinanceRequestType} from '../../modules/user-panel/models/refinance-types';
import {ClientLoanStateTypes} from '../../modules/user-panel/models/client-loan-types';

export const selectUser = (state: AppState) => state.user;

export const selectUsername = (state: AppState) => state.user && (state.user.firstName + ' ' + state.user.lastName);

export const selectBankConfirmationsStatus = (state: AppState) => state.user && state.user.confirmedBankAccount;

export const selectLoanInfo = (state: AppState) => state.user.loanInfo;

export const selectLoanCurrentInfo = createSelector(
  selectLoanInfo,
  (loanInfo) => loanInfo && loanInfo.currentInfo
);

export const selectLoanCurrentUploadedDocuments = createSelector(
  selectLoanInfo,
  (loanInfo) => loanInfo && loanInfo.uploadedDocuments
);


export const selectFullUserData = createSelector(
  selectUser,
  state => state && state.fullUserData
);

export const selectRefinanceOptions = createSelector(
  selectLoanInfo,
  (loanInfo) => loanInfo && loanInfo.refinanceOptions
);

export const selectCurrentRefinanceOption = createSelector(
  selectRefinanceOptions,
  selectLoanCurrentInfo,
  (options, currentInfo) => {
    if (options && currentInfo && currentInfo.refinanceState !== RefinanceRequestType.None) {
      const currentOpt = options.refinanceOptions.filter(opt => opt && opt.term === currentInfo.refinanceTerm);
      if (currentOpt) {
        return currentOpt[0];
      }
    } else {
      return null;
    }
  }
);

export const selectLoanState = createSelector(
  selectLoanInfo,
  (loanInfo) => loanInfo && loanInfo.loanState
);

export const selectLoanSchedule = createSelector(
  selectLoanInfo,
  (loanInfo) => loanInfo && loanInfo.loanSchedule
);

export const selectLoanHistory = createSelector(
  selectLoanInfo,
  (loanInfo) => loanInfo && loanInfo.loanHistory &&
    loanInfo.loanHistory.filter(el => el.status === ClientLoanStateTypes.Repaid || el.status === ClientLoanStateTypes.Current)
);

export const selectLoanHistoryLoadedStatus = createSelector(
  selectLoanInfo,
  (loanInfo) => loanInfo && loanInfo.loanHistoryLoaded
);

export const selectLoanRequestStatus = createSelector(
  selectLoanInfo,
  (loanInfo) => loanInfo && loanInfo.loanRequestSuccess
);

export const selectLoanSmsVerificationStatus = createSelector(
  selectLoanInfo,
  (loanInfo) => loanInfo && loanInfo.loanSmsVerified
);

export const selectLoanSmsVerificationError = createSelector(
  selectLoanInfo,
  (loanInfo) => loanInfo && loanInfo.loanSmsError
);

export const selectLoanVerificationFormSubmittedStatus = createSelector(
  selectLoanInfo,
  (loanInfo) => loanInfo && loanInfo.formSubmitted
);

export const selectUserNotifications = createSelector(
  selectUser,
  (userData) => userData && userData.notifications
);

export const selectOpenedPopup = (state: AppState) => state.user.editorPopup;

export const selectChangingState = (state: AppState) => state.user.changingState;

export const selectLoanConfirmationSmsRequestedStatus = createSelector(
  selectLoanInfo,
  (loanInfo) => loanInfo && loanInfo.confirmationLoanSmsRequestedStatus
);

export const selectExtensionLoanType = (state: AppState) => state.user.extensionLoanType;
