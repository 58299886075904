import { InfoState, initialInfoState } from '../state/info.state';
import { InfoActions, InfoActionTypes } from '../actions';
import * as _ from 'lodash';

export function infoReducer(state: InfoState = initialInfoState, action: InfoActions): InfoState {
  switch (action.type) {
    case InfoActionTypes.SetConditions:
      return {
        ...state,
        conditions: [...action.payload.conditions]
      };
    case InfoActionTypes.SetContacts:
      return {
        ...state,
        contacts: action.payload.contacts
      };
    case InfoActionTypes.LoadInfoError:
      return {
        ...state,
        infoError: action.payload.loadInfoError
      };
    case InfoActionTypes.SetMainPageInfo:
      return {
        ...state,
        mainPageData: action.payload.mainPageData,
      };
    case InfoActionTypes.SetRefBanks:
      return {
        ...state,
        banks: action.payload.banks
      };
    case InfoActionTypes.SetRefJobs:
      return {
        ...state,
        occupations: action.payload.occupations,
        ranges: action.payload.ranges,
        sources: action.payload.sources
      };
    case InfoActionTypes.SetCurrentDate:
      return {
        ...state,
        dateTime: action.payload
      };
    case InfoActionTypes.SetCalculatorAmount:
      return {
        ...state,
        calculator: Object.assign({ ...state.calculator }, { amount: action.payload })
      };
    case InfoActionTypes.SetCalculatorTerm:
      return {
        ...state,
        calculator: Object.assign({ ...state.calculator }, {
          term: action.term,
          maxTerm: action.maxTerm,
          minTerm: action.minTerm,
          amountThreshold: action.amountThreshold ? action.amountThreshold : state.calculator ? state.calculator.amountThreshold : 2000,
          maxAmount: action.maxAmount ? action.maxAmount : state.calculator.maxAmount,
          minAmount: action.minAmount ? action.minAmount : state.calculator.minAmount,
          error: action.error ? action.error : '',
          isFirstLoan: (action.isFirstLoan != undefined && action.isFirstLoan != null) ? action.isFirstLoan : state.calculator.isFirstLoan
        })
      };
    case InfoActionTypes.SetCalculatorCommission:
      return {
        ...state,
        calculator: Object.assign({ ...state.calculator }, { commission: action.payload })
      };
    case InfoActionTypes.SetAcceptanceText:
      return {
        ...state,
        acceptanceText: action.text
      };
    case InfoActionTypes.SetDocs:
      const docsArr = state.docs ? [...state.docs] : [];
      const newDocs = Object.assign({}, { loanNumber: action.payload.loanNumber, docs: action.payload.docs });
      docsArr.push(newDocs);
      return {
        ...state,
        docs: _.uniqBy(docsArr, 'loanNumber')
      };
    case InfoActionTypes.SetCommonErrorMessage:
      return {
        ...state,
        commonErrorMessage: action.payload.errMessage
      };
    case InfoActionTypes.SetCommonSuccessRedirect:
      return {
        ...state,
        commonSuccessRedirect: action.payload.page
      };
    case InfoActionTypes.SetCommonSuccessMessage:
      return {
        ...state,
        commonSuccessMessage: action.payload.successMessage
      };
    case InfoActionTypes.SetCalculatorLoadStatus:
      return {
        ...state,
        calculatorLoaded: action.status
      };

    case InfoActionTypes.SetFormularDoc:
      return {
        ...state,
        formular: action.payload.formularDoc,
        parentPage: action.payload.parent
      };
    case InfoActionTypes.SetContentPage:
      return {
        ...state,
        pageContent: action.payload.page,
        parentPage: action.payload.parent
      };
    default:
      return state;
  }
}
