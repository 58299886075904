import { Injectable } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, Event as NavigationEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ApiService } from './api.service';
import { CookieService } from 'ngx-cookie-service';
import { ofType, Actions } from '@ngrx/effects';
import { AuthenticationActionTypes, LogOut, LogIn, SetAuthenticationStatus, SignUpComplete } from 'src/app/store/actions';
import { Store } from '@ngrx/store';
import { AppState } from '../store/state';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { globalVariables } from 'src/environments/globalVariables';
import { DeviceDetectorService } from 'ngx-device-detector';

enum AffiliatePageType {
    Any = 1,
    Home,
    Profile,
    ThankYou,
    Public,
    PostView,
    Registration
}

@Injectable({
    providedIn: 'root'
  })
export class AffiliateService {
    constructor(
        private router: Router,
        private apiService: ApiService,
        private store: Store<AppState>,
        private authService: AuthService,
        private cookieService: CookieService,
        private actions: Actions,
        private deviceService: DeviceDetectorService) {
    }

    private docHead: string = null;
    private scripts: string[] = [];
    private justRegistered = false;

    public subscribe() {

        if (this.authService.getAuthToken()) {
        if (this.authService.getExpiredToken() &&
        (new Date()).getTime() < (Number(this.authService.getExpiredToken()))) {
            this.authService.setExpiredToken((String)((new Date()).getTime() + environment.tokenExpireTime));
        } else {
            this.store.dispatch(new LogOut());
            this.store.dispatch(new SetAuthenticationStatus({isAuthenticated: false, token: null}));
        }}

        this.router.events.subscribe((event: NavigationEvent) => {
            if (event instanceof NavigationEnd) {
                this.apiService.post(`/${environment.AppID}/GetAffiliateCodes`, {
                    currentUrl: location.protocol + '//' + location.host + (event as NavigationEnd).urlAfterRedirects,
                    affiliateUrl: navigator.cookieEnabled ? this.cookieService.get('FinSasAff') :
                        globalVariables.FinSasAff,
                    affiliatePageType: this.GetPageType((event as NavigationEnd).urlAfterRedirects),
                    accessToken: this.authService.getAuthToken()
                })
                .subscribe(
                    (val) => {
                        if (val && val.success === true) {
                            if (this.docHead === null || val.Header.localeCompare(this.docHead) !== 0) {
                                // tslint:disable-next-line:max-line-length
                                document.getElementsByTagName('head')[0].innerHTML = document.getElementsByTagName('head')[0].innerHTML.replace(this.docHead, '') + val.Header;
                                this.docHead = val.Header;
                                this.LoadScripts(val.Header);
                                // console.log('new head');
                            }

                            document.getElementById('BodyTop').innerHTML = val.BodyTop;
                            this.LoadScripts(val.BodyTop);

                            document.getElementById('BodyBottom').innerHTML = val.BodyBottom;
                            this.LoadScripts(val.BodyBottom);
                        }
                    });
            } else if (event instanceof NavigationStart && event.url.toLowerCase().startsWith('/client/loginref')) {
                let urlTree = this.router.parseUrl(event.url);
                let password = urlTree.queryParams.id;

                const isMobile = this.deviceService.isMobile();

                this.router.navigate([`/`]);
                
                if ((urlTree.queryParams.page && urlTree.queryParams.page == 'profile') || (urlTree.queryParams.page && urlTree.queryParams.page == 'resignnews')) {
                    if (navigator.cookieEnabled) {
                        window.localStorage.setItem('redirectToProfile', '1');
                    } else {
                        globalVariables.redirectToProfile = '1';
                    }
                    if (urlTree.queryParams.page == 'resignnews') {
                        if (navigator.cookieEnabled) {
                            window.localStorage.setItem('resignnews', '1');
                        } else {
                            globalVariables.resignnews = '1';
                        }
                    }
                }

                this.store.dispatch(new LogIn({
                    username: 'login_url',
                    password,
                    isMobile
                }));
            }
        });

        this.actions.pipe(ofType<SignUpComplete>(AuthenticationActionTypes.SignUpComplete)).subscribe(() => this.justRegistered = true );
    }

    private GetPageType(path: string): AffiliatePageType {
        if (path.startsWith('/profil')) {
            if (this.justRegistered) {
                this.justRegistered = false;
                return AffiliatePageType.ThankYou;
            }

            return AffiliatePageType.Profile;
        } else if (path.startsWith('/rejestracja')) {
            return AffiliatePageType.Registration;
        } else if (path === '/') {
            return AffiliatePageType.Home;
        } else {
            return AffiliatePageType.Any;
        }
    }

    private LoadScripts(html: string) {
        if (html && html.length > 0) {
            // tslint:disable-next-line:prefer-const
            let dv = document.createElement('div');
            dv.innerHTML = html;
            const arr = dv.getElementsByTagName('script');
            // tslint:disable-next-line:prefer-for-of
            for (let n = 0; n < arr.length; n++) {
                if (!this.scripts.includes(arr[n].innerHTML)) {
                    try {
                        // tslint:disable-next-line:no-eval
                        eval(arr[n].innerHTML);
                    } catch (err) {
                        console.log(err.message);
                    }
                    this.scripts.push(arr[n].innerHTML);
                    // console.log('eval: ' + this.scripts.length);
                }
            }
        }
    }
}
