import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-agreement-big-content',
  templateUrl: './agreement-big-content.component.html',
  styleUrls: ['./agreement-big-content.component.css']
})
export class AgreementBigContentComponent implements OnInit {

  public ADDRESS: string;

  constructor() {
    this.ADDRESS = environment.ADDRESS;
   }

  ngOnInit() {
  }

}
