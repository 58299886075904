import {Component, Input, OnInit} from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer-info',
  templateUrl: './footer-info.component.html',
  styleUrls: ['./footer-info.component.scss']
})
export class FooterInfoComponent implements OnInit {
  [x: string]: any;
  @Input()
  public infoContent?: any;
  public showFullText: boolean;
  public ADDRESS: string; 
  public KRS: string;
  public NIP: string; 
  public REGON: string; 
  
  constructor() { 
    this.ADDRESS = environment.ADDRESS;
    this.KRS = environment.KRS;
    this.NIP = environment.NIP;
    this.REGON = environment.REGON;
    
  }

  ngOnInit() {
  }

  public toggleFullText() {
    this.showFullText = !this.showFullText;
  }
}
