import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-privacy-policy-content',
  templateUrl: './privacy-policy-content.component.html',
  styleUrls: ['./privacy-policy-content.component.css']
})
export class PrivacyPolicyContentComponent implements OnInit {

  public KRS: string;
  public NIP: string;
  public REGON: string;
  public ADDRESS: string;
  public URL: string;
  public EMAIL_IOD: string;

  constructor() {
    this.KRS = environment.KRS;
    this.NIP = environment.NIP;
    this.REGON = environment.REGON;
    this.ADDRESS = environment.ADDRESS;
    this.URL = environment.URL;
    this.EMAIL_IOD = environment.EMAIL_IOD;
   }

  ngOnInit() {
  }

}
