import { ModalTypes } from 'src/app/enums';
const registeredCountries = [
  {id: 1, title: 'Polska'},
  {id: 2, title: '---'},
  {id: 3, title: 'Afganistan'},
  {id: 4, title: 'Albania'},
  {id: 5, title: 'Algieria'},
  {id: 6, title: 'Andora'},
  {id: 7, title: 'Angola'},
  {id: 8, title: 'Anguilla'},
  {id: 9, title: 'Antarktyka'},
  {id: 10, title: 'Antigua i Barbuda'},
  {id: 11, title: 'Arabia Saudyjska'},
  {id: 12, title: 'Argentyna'},
  {id: 13, title: 'Armenia'},
  {id: 14, title: 'Aruba'},
  {id: 15, title: 'Australia'},
  {id: 16, title: 'Austria'},
  {id: 17, title: 'Azerbejdżan'},
  {id: 18, title: 'Bahamy'},
  {id: 19, title: 'Bahrajn'},
  {id: 20, title: 'Bangladesz'},
  {id: 21, title: 'Barbados'},
  {id: 22, title: 'Belgia'},
  {id: 23, title: 'Belize'},
  {id: 24, title: 'Benin'},
  {id: 25, title: 'Bermudy'},
  {id: 26, title: 'Bhutan'},
  {id: 27, title: 'Białoruś'},
  {id: 28, title: 'Boliwia'},
  {id: 29, title: 'Bośnia i Hercegowina'},
  {id: 30, title: 'Botswana'},
  {id: 31, title: 'Brazylia'},
  {id: 32, title: 'Brunei'},
  {id: 33, title: 'Brytyjskie Terytorium Oceanu Indyjskiego'},
  {id: 34, title: 'Brytyjskie Wyspy Dziewicze'},
  {id: 35, title: 'Bułgaria'},
  {id: 36, title: 'Burkina Faso'},
  {id: 37, title: 'Burundi'},
  {id: 38, title: 'Ceuta i Melilla'},
  {id: 39, title: 'Chile'},
  {id: 40, title: 'Chiny'},
  {id: 41, title: 'Chorwacja'},
  {id: 42, title: 'Côte d’Ivoire'},
  {id: 43, title: 'Curaçao'},
  {id: 44, title: 'Cypr'},
  {id: 45, title: 'Czad'},
  {id: 46, title: 'Czarnogóra'},
  {id: 47, title: 'Czechy'},
  {id: 48, title: 'Dalekie Wyspy Mniejsze Stanów Zjednoczonych'},
  {id: 49, title: 'Dania'},
  {id: 50, title: 'Demokratyczna Republika Konga'},
  {id: 51, title: 'Diego Garcia'},
  {id: 52, title: 'Dominika'},
  {id: 53, title: 'Dominikana'},
  {id: 54, title: 'Dżibuti'},
  {id: 55, title: 'Egipt'},
  {id: 56, title: 'Ekwador'},
  {id: 57, title: 'Erytrea'},
  {id: 58, title: 'Estonia'},
  {id: 59, title: 'Etiopia'},
  {id: 60, title: 'Eurozone'},
  {id: 61, title: 'Falklandy'},
  {id: 62, title: 'Fidżi'},
  {id: 63, title: 'Filipiny'},
  {id: 64, title: 'Finlandia'},
  {id: 65, title: 'Francja'},
  {id: 66, title: 'Francuskie Terytoria Południowe i Antarktyczne'},
  {id: 67, title: 'Gabon'},
  {id: 68, title: 'Gambia'},
  {id: 69, title: 'Georgia Południowa i Sandwich Południowy'},
  {id: 70, title: 'Ghana'},
  {id: 71, title: 'Gibraltar'},
  {id: 72, title: 'Grecja'},
  {id: 73, title: 'Grenada'},
  {id: 74, title: 'Grenlandia'},
  {id: 75, title: 'Gruzja'},
  {id: 76, title: 'Guam'},
  {id: 77, title: 'Guernsey'},
  {id: 78, title: 'Gujana'},
  {id: 79, title: 'Gujana Francuska'},
  {id: 80, title: 'Gwadelupa'},
  {id: 81, title: 'Gwatemala'},
  {id: 82, title: 'Gwinea'},
  {id: 83, title: 'Gwinea Bissau'},
  {id: 84, title: 'Gwinea Równikowa'},
  {id: 85, title: 'Haiti'},
  {id: 86, title: 'Hiszpania'},
  {id: 87, title: 'Holandia'},
  {id: 88, title: 'Honduras'},
  {id: 89, title: 'Indie'},
  {id: 90, title: 'Indonezja'},
  {id: 91, title: 'Irak'},
  {id: 92, title: 'Iran'},
  {id: 93, title: 'Irlandia'},
  {id: 94, title: 'Islandia'},
  {id: 95, title: 'Izrael'},
  {id: 96, title: 'Jamajka'},
  {id: 97, title: 'Japonia'},
  {id: 98, title: 'Jemen'},
  {id: 99, title: 'Jersey'},
  {id: 100, title: 'Jordania'},
  {id: 101, title: 'Kajmany'},
  {id: 102, title: 'Kambodża'},
  {id: 103, title: 'Kamerun'},
  {id: 104, title: 'Kanada'},
  {id: 105, title: 'Katar'},
  {id: 106, title: 'Kazachstan'},
  {id: 107, title: 'Kenia'},
  {id: 108, title: 'Kirgistan'},
  {id: 109, title: 'Kiribati'},
  {id: 110, title: 'Kolumbia'},
  {id: 111, title: 'Komory'},
  {id: 112, title: 'Kongo'},
  {id: 113, title: 'Korea Południowa'},
  {id: 114, title: 'Korea Północna'},
  {id: 115, title: 'Kosowo'},
  {id: 116, title: 'Kostaryka'},
  {id: 117, title: 'Kuba'},
  {id: 118, title: 'Kuwejt'},
  {id: 119, title: 'Laos'},
  {id: 120, title: 'Lesotho'},
  {id: 121, title: 'Liban'},
  {id: 122, title: 'Liberia'},
  {id: 123, title: 'Libia'},
  {id: 124, title: 'Liechtenstein'},
  {id: 125, title: 'Litwa'},
  {id: 126, title: 'Luksemburg'},
  {id: 127, title: 'Łotwa'},
  {id: 128, title: 'Macedonia'},
  {id: 129, title: 'Madagaskar'},
  {id: 130, title: 'Majotta'},
  {id: 131, title: 'Malawi'},
  {id: 132, title: 'Malediwy'},
  {id: 133, title: 'Malezja'},
  {id: 134, title: 'Mali'},
  {id: 135, title: 'Malta'},
  {id: 136, title: 'Mariany Północne'},
  {id: 137, title: 'Maroko'},
  {id: 138, title: 'Martynika'},
  {id: 139, title: 'Mauretania'},
  {id: 140, title: 'Mauritius'},
  {id: 141, title: 'Meksyk'},
  {id: 142, title: 'Mikronezja'},
  {id: 143, title: 'Mjanma'},
  {id: 144, title: 'Mołdawia'},
  {id: 145, title: 'Monako'},
  {id: 146, title: 'Mongolia'},
  {id: 147, title: 'Montserrat'},
  {id: 148, title: 'Mozambik'},
  {id: 149, title: 'Namibia'},
  {id: 150, title: 'Nauru'},
  {id: 151, title: 'Nepal'},
  {id: 152, title: 'Niderlandy Karaibskie'},
  {id: 153, title: 'Niemcy'},
  {id: 154, title: 'Niger'},
  {id: 155, title: 'Nigeria'},
  {id: 156, title: 'Nikaragua'},
  {id: 157, title: 'Niue'},
  {id: 158, title: 'Norfolk'},
  {id: 159, title: 'Norwegia'},
  {id: 160, title: 'Nowa Kaledonia'},
  {id: 161, title: 'Nowa Zelandia'},
  {id: 162, title: 'Oman'},
  {id: 163, title: 'Organizacja Narodów Zjednoczonych'},
  {id: 164, title: 'Pakistan'},
  {id: 165, title: 'Palau'},
  {id: 166, title: 'Panama'},
  {id: 167, title: 'Papua-Nowa Gwinea'},
  {id: 168, title: 'Paragwaj'},
  {id: 169, title: 'Peru'},
  {id: 170, title: 'Pitcairn'},
  {id: 171, title: 'Polinezja Francuska'},
  {id: 172, title: 'Polska'},
  {id: 173, title: 'Portoryko'},
  {id: 174, title: 'Portugalia'},
  {id: 175, title: 'Republika Południowej Afryki'},
  {id: 176, title: 'Republika Środkowoafrykańska'},
  {id: 177, title: 'Republika Zielonego Przylądka'},
  {id: 178, title: 'Reunion'},
  {id: 179, title: 'Rosja'},
  {id: 180, title: 'Rumunia'},
  {id: 181, title: 'Rwanda'},
  {id: 182, title: 'Sahara Zachodnia'},
  {id: 183, title: 'Saint Kitts i Nevis'},
  {id: 184, title: 'Saint Lucia'},
  {id: 185, title: 'Saint Vincent i Grenadyny'},
  {id: 186, title: 'Saint-Barthélemy'},
  {id: 187, title: 'Saint-Martin'},
  {id: 188, title: 'Saint-Pierre i Miquelon'},
  {id: 189, title: 'Salwador'},
  {id: 190, title: 'Samoa'},
  {id: 191, title: 'Samoa Amerykańskie'},
  {id: 192, title: 'San Marino'},
  {id: 193, title: 'Senegal'},
  {id: 194, title: 'Serbia'},
  {id: 195, title: 'Seszele'},
  {id: 196, title: 'Sierra Leone'},
  {id: 197, title: 'Singapur'},
  {id: 198, title: 'Sint Maarten'},
  {id: 199, title: 'Słowacja'},
  {id: 200, title: 'Słowenia'},
  {id: 201, title: 'Somalia'},
  {id: 202, title: 'SRA Hongkong'},
  {id: 203, title: 'SRA Makau'},
  {id: 204, title: 'Sri Lanka'},
  {id: 205, title: 'Stany Zjednoczone'},
  {id: 206, title: 'Suazi'},
  {id: 207, title: 'Sudan'},
  {id: 208, title: 'Sudan Południowy'},
  {id: 209, title: 'Surinam'},
  {id: 210, title: 'Svalbard i Jan Mayen'},
  {id: 211, title: 'Syria'},
  {id: 212, title: 'Szwajcaria'},
  {id: 213, title: 'Szwecja'},
  {id: 214, title: 'Tadżykistan'},
  {id: 215, title: 'Tajlandia'},
  {id: 216, title: 'Tajwan'},
  {id: 217, title: 'Tanzania'},
  {id: 218, title: 'Terytoria Palestyńskie'},
  {id: 219, title: 'Timor Wschodni'},
  {id: 220, title: 'Togo'},
  {id: 221, title: 'Tokelau'},
  {id: 222, title: 'Tonga'},
  {id: 223, title: 'Tristan da Cunha'},
  {id: 224, title: 'Trynidad i Tobago'},
  {id: 225, title: 'Tunezja'},
  {id: 226, title: 'Turcja'},
  {id: 227, title: 'Turkmenistan'},
  {id: 228, title: 'Turks i Caicos'},
  {id: 229, title: 'Tuvalu'},
  {id: 230, title: 'Uganda'},
  {id: 231, title: 'Ukraina'},
  {id: 232, title: 'Urugwaj'},
  {id: 233, title: 'Uzbekistan'},
  {id: 234, title: 'Vanuatu'},
  {id: 235, title: 'Wallis i Futuna'},
  {id: 236, title: 'Watykan'},
  {id: 237, title: 'Wenezuela'},
  {id: 238, title: 'Węgry'},
  {id: 239, title: 'Wielka Brytania'},
  {id: 240, title: 'Wietnam'},
  {id: 241, title: 'Włochy'},
  {id: 242, title: 'Wyspa Bożego Narodzenia'},
  {id: 243, title: 'Wyspa Man'},
  {id: 244, title: 'Wyspa Świętej Heleny'},
  {id: 245, title: 'Wyspa Wniebowstąpienia'},
  {id: 246, title: 'Wyspy Alandzkie'},
  {id: 247, title: 'Wyspy Cooka'},
  {id: 248, title: 'Wyspy Dziewicze Stanów Zjednoczonych'},
  {id: 249, title: 'Wyspy Kanaryjskie'},
  {id: 250, title: 'Wyspy Kokosowe'},
  {id: 251, title: 'Wyspy Marshalla'},
  {id: 252, title: 'Wyspy Owcze'},
  {id: 253, title: 'Wyspy Salomona'},
  {id: 254, title: 'Wyspy Świętego Tomasza i Książęca'},
  {id: 255, title: 'Zambia'},
  {id: 256, title: 'Zimbabwe'},
  {id: 257, title: 'Zjednoczone Emiraty Arabskie'},
];

interface NewsItem {
  Id: number;
  Text: string;
  Name: string;
  Age: number;
  City: string;
  Rating: number;
}

interface CalculatorData {
  amount?: number;
  term?: number;
  maxTerm?: number;
  minTerm?: number;
  commission?: number;
  amountThreshold?: number;
  maxAmount?: number;
  minAmount?: number;
  error?: string;
  isFirstLoan?: boolean
}

export interface MainPageData {
  image: any;
  comments: any[] | null;
  news: NewsItem[] | null;
}

export interface InfoState {
  contacts?: any;
  mainPageData?: MainPageData;
  conditions?: any[];
  banks?: any[];
  occupations?: any[];
  ranges?: any[];
  sources?: any[];
  countries: any[];
  infoError?: any;
  dateTime?: string;
  calculator?: CalculatorData;
  calculatorLoaded?: boolean;
  acceptanceText?: string;
  docs?: DocsModel[];
  commonErrorMessage?: string;
  commonSuccessMessage?: string;
  commonSuccessRedirect?: string;
  formular?: FormularDoc;
  pageContent?: string;
  parentPage?: ModalTypes;
}

export const initialInfoState = {
  contacts: null,
  infoError: null,
  mainPageData: null,
  occupations: [],
  banks: [],
  ranges: [],
  sources: [],
  conditions: [],
  countries: registeredCountries,
};

export interface DocsModel {
  loanNumber: any;
  docs: any[];
}

export interface FormularDoc {
  title: string;
  data: string;
  type: string;
  
}
