import { initialUserState, UserState } from '../state/user.state';
import { UserActions, UserActionTypes } from '../actions';
import { ChangingStateTypes } from '../../modules/user-panel/models/changing-state-types';

export function userReducer(state: UserState = initialUserState, action: UserActions): UserState {
  switch (action.type) {
    case UserActionTypes.SetUserData:
      return {
        ...state,
        ...action.payload.userData,
        fullUserData: action.payload.userData
      };
    case UserActionTypes.SetLoanSchedule:
      return {
        ...state,
        loanInfo: Object.assign({}, { ...state.loanInfo, loanSchedule: action.payload.loanSchedule })
      };
    case UserActionTypes.SetLoanState:
      return {
        ...state,
        loanInfo: Object.assign({}, { ...state.loanInfo, loanState: action.payload.loanState })
      };
    case UserActionTypes.SetRefinanceOptions:
      return {
        ...state,
        loanInfo: Object.assign({}, { ...state.loanInfo, refinanceOptions: action.payload.refinanceOptions })
      };
    case UserActionTypes.SetLoanCurrentInfo:
      return {
        ...state,
        loanInfo: Object.assign({}, { ...state.loanInfo, currentInfo: { ...state.loanInfo.currentInfo, ...action.payload.loanCurrentInfo } })
      };
    case UserActionTypes.LoadLoanError:
      return {
        ...state,
        loanInfo: Object.assign({}, { ...state.loanInfo, error: action.payload.errMessage })
      };
    case UserActionTypes.LoanRequestStatusClear:
      return {
        ...state,
        loanInfo: {
          ...state.loanInfo,
          loanRequestSuccess: undefined,
        }
      };
    case UserActionTypes.LoanRequestSuccess:
      return {
        ...state,
        loanInfo: {
          ...state.loanInfo,
          loanRequestSuccess: true,
          currentInfo: { ...state.loanInfo.currentInfo, loanRequestId: action.payload.newId }
        }
      };
    case UserActionTypes.LoanRequestError:
      return {
        ...state,
        loanInfo: { ...state.loanInfo, loanRequestError: action.payload.errMessage, loanRequestSuccess: false }
      };
    case UserActionTypes.RefinanceRequestSuccess:
      return {
        ...state,
        loanInfo: { ...state.loanInfo, refinanceRequestSuccess: true }
      };
    case UserActionTypes.RefinanceRequestError:
      return {
        ...state,
        loanInfo: { ...state.loanInfo, refinanceRequestError: action.payload.errMessage, refinanceRequestSuccess: false }
      };
    case UserActionTypes.OpenUserPopup:
      return {
        ...state,
        editorPopup: action.popupType
      };
    case UserActionTypes.CloseUserPopup:
      return {
        ...state,
        editorPopup: null
      };
    case UserActionTypes.SetCurrentRefinanceOptions:
      const getRightFee = () =>
        action.payload.fee === null ? null :
          action.payload.fee ? action.payload.fee :
            state.loanInfo.currentInfo.refinanceFee;

      return {
        ...state,
        loanInfo: {
          ...state.loanInfo, currentInfo: {
            ...state.loanInfo.currentInfo,
            refinanceFee: getRightFee(),
            refinanceState: action.payload.type,
            refinanceTerm: action.payload.term
          }
        },
      };
    case UserActionTypes.LoadIncomeDocumentsSuccess:
      return {
        ...state,
        loanInfo: { ...state.loanInfo, uploadedDocuments: action.payload.documents }
      };
    case UserActionTypes.ChangeEmailSuccess:
      let emailStateToReturn: UserState = {
        ...state,
        changingState: action.payload.state
      };

      if (action.payload.state === ChangingStateTypes.Success) {
        emailStateToReturn = Object.assign({}, {
          ...state,
          changingState: action.payload.state,
          eMail: action.payload.newEmail,
          fullUserData: {
            ...state.fullUserData,
            eMail: action.payload.newEmail,
          }
        });
      }
      return emailStateToReturn;
    case UserActionTypes.ChangeEmailError:
      return {
        ...state,
        changingState: action.payload.state,
        phoneChangingMessage: action.payload.errMessage
      };
    case UserActionTypes.ChangePhoneSuccess:
      let phoneStateToReturn: UserState = {
        ...state,
        changingState: action.payload.state
      };

      if (action.payload.state === ChangingStateTypes.Success) {
        phoneStateToReturn = Object.assign({}, {
          ...state,
          changingState: action.payload.state,
          phone: '+48 ' + action.payload.newPhone,
          fullUserData: {
            ...state.fullUserData,
            phone: '+48 ' + action.payload.newPhone,
          }
        });
      }
      return phoneStateToReturn;
    case UserActionTypes.SetChangingState:
      return {
        ...state,
        changingState: action.state
      };
    case UserActionTypes.ChangePhoneError:
      return {
        ...state,
        changingState: action.payload.state,
        phoneChangingMessage: action.payload.errMessage
      };
    case UserActionTypes.BeforeRequestLoanSuccess:
      return {
        ...state,
        confirmedBankAccount: action.payload.confirmedBankAccount,
        updateExpenses: action.payload.updateExpenses,
        marketingAcceptedPartner: action.payload.updateIncome,
        showDiscountRegulations: action.payload.showDiscountRegulations,
        fullUserData: {
          ...state.fullUserData,
          confirmedBankAccount: action.payload.confirmedBankAccount,
        }
      };
    case UserActionTypes.MarketingSubscriptionSuccess:
      return { ...state, receiveNews: action.payload.turnEmailOn, marketingAccepted: action.payload.turnSmsOn, marketingAcceptedPartner: action.payload.turnPartnerOn };
    case UserActionTypes.MarketingSubscriptionError:
      // @TODO add error state
      return {
        ...state,
      };
    case UserActionTypes.SetLoanHistory:
      return {
        ...state,
        loanInfo: { ...state.loanInfo, loanHistory: action.payload.loanHistory }
      };
    case UserActionTypes.SetLoanHistoryLoadedStatus:
      return {
        ...state,
        loanInfo: { ...state.loanInfo, loanHistoryLoaded: action.loaded }
      };
    case UserActionTypes.AffiliateCompleteRegistrationSuccess:
      return {
        ...state,
        fullUserData: {
          ...state.fullUserData,
          setPassword: false,
          acceptTerms: true,
          registrationComplete: true
        }
      };
    case UserActionTypes.LoanSmsVerificationSuccess:
      return {
        ...state,
        loanInfo: {
          ...state.loanInfo,
          loanSmsVerified: true,
          currentInfo: { ...state.loanInfo.currentInfo, isVerifiedBySms: true }
        }
      };
    case UserActionTypes.LoanSmsVerificationError:
      return {
        ...state,
        loanInfo: { ...state.loanInfo, loanSmsVerified: false, loanSmsError: action.payload.errMessage }
      };
    case UserActionTypes.SetLoanSmsVerificationStatus:
      return {
        ...state,
        loanInfo: { ...state.loanInfo, loanSmsVerified: action.payload.status }
      };
    case UserActionTypes.SetLoanVerificationFormSubmittedStatus:
      return {
        ...state,
        loanInfo: { ...state.loanInfo, formSubmitted: action.isSubmitted }
      };
    case UserActionTypes.SetUserNotifications:
      return {
        ...state,
        notifications: action.payload.notifications
      };
    case UserActionTypes.LoanVerificationRequested:
      return {
        ...state,
        loanVerificationRequested: action.payload,
      };
    case UserActionTypes.SetLoanConfirmationSmsRequestedStatus:
      return {
        ...state,
        loanInfo: { ...state.loanInfo, confirmationLoanSmsRequestedStatus: action.status }
      };
    case UserActionTypes.ChooseExtensionLoanType:
      return {
        ...state,
        extensionLoanType: action.extensionType
      };
    case UserActionTypes.CleanUser:
      return initialUserState;
    default:
      return state;
  }
}
