import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-marketing-agreement-content',
  templateUrl: './marketing-agreement-content.component.html',
  styleUrls: ['./marketing-agreement-content.component.css']
})
export class MarketingAgreementContentComponent implements OnInit {

  public ADDRESS: string;

  constructor() { 
    this.ADDRESS = environment.ADDRESS;
  }

  ngOnInit() {
  }

}
