import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-electronic-agreement-content',
  templateUrl: './electronic-agreement-content.component.html',
  styleUrls: ['./electronic-agreement-content.component.css']
})
export class ElectronicAgreementContentComponent implements OnInit {

  public ADDRESS: string;

  constructor() { 
    this.ADDRESS = environment.ADDRESS;
  }

  ngOnInit() {
  }

}
