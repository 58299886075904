import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-authority-content',
  templateUrl: './authority-content.component.html',
  styleUrls: ['./authority-content.component.css']
})
export class AuthorityContentComponent implements OnInit {

  public KRS: string;
  public ADDRESS: string;

  constructor() { 
    this.ADDRESS = environment.ADDRESS;
    this.KRS = environment.KRS;
  }

  ngOnInit() {
  }

}
