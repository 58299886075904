import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-client-agreement-content',
  templateUrl: './client-agreement-content.component.html',
  styleUrls: ['./client-agreement-content.component.css']
})
export class ClientAgreementContentComponent implements OnInit {

  public ADDRESS: string;

  constructor() {
    this.ADDRESS = environment.ADDRESS;
   }

  ngOnInit() {
  }

}
