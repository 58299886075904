// tslint:disable-next-line:max-line-length
export const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const debounce = (callback, wait) => {
  let timeout = null;
  return (...args) => {
    const next = () => callback(...args);
    clearTimeout(timeout);
    timeout = setTimeout(next, wait);
  };
};

export const parseJWT = (token: string) => {
  if (!token || !token.length) {
    return null;
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(window.atob(base64));
};

export const normalizeErrorString = (errString: string): string => {
  if (!errString) {
    return '';
  }


  switch (errString) {
    case 'CLIENT_NOT_FOUND': return 'Klient nieodnaleziony';
    case 'INCORRECT_PASSWORD': return 'Hasło lub login jest nieprawidłowy';
    case 'VALIDATION_ERROR': return 'Wprowadzono niepoprawne dane';
    case 'CONTENT_REG_CLIENT_PHONE_EXISTS': return 'Wprowadzono niepoprawne dane';
    case 'CONTENT_REG_CLIENT_EMAIL_EXISTS': return 'Wprowadzono niepoprawne dane';
    case 'CONTENT_REG_INVALID_PROMOTION_CODE': return 'Wprowadzono niepoprawne dane';
    case 'CONTENT_REG_CLIENT_IDENTITYCODE_EXISTS': return 'Wprowadzono niepoprawne dane';
  }

  const normalized = errString.split('_').map((el, i) => {
    el = el.toLowerCase();
    if (i === 0) {
      el = el.charAt(0).toUpperCase() + el.substring(1);
    }
    return el;
  });

  return normalized.join(' ');
};
