import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {AuthService} from 'src/app/services/auth.service';
import {Observable, of, Subject} from 'rxjs';
import {HttpHeaders} from '@angular/common/http';
import {ClipboardService} from 'ngx-clipboard';

const mockComments = [
  /*{
    // tslint:disable-next-line:max-line-length
    Text: `Nie wymagają żadnych zaświadczeń tak jak inne firmy, a kasa bardzo szybko znalazła się na moim koncie. Jak dla mnie uczciwa firma udzielająca chwilówek.`,
    Name: 'Weronika',
    City: 'Szczecin',
    Rating: 5,
  },
  {
    // tslint:disable-next-line:max-line-length
    Text: `Nie wymagają żadnych zaświadczeń tak jak inne firmy, a kasa bardzo szybko znalazła się na moim koncie. Jak dla mnie uczciwa firma udzielająca chwilówek.`,
    Name: 'Krzysztof',
    City: 'Katowice',
    Rating: 5,
  },
  {
    // tslint:disable-next-line:max-line-length
    Text: `Nie wymagają żadnych zaświadczeń tak jak inne firmy, a kasa bardzo szybko znalazła się na moim koncie. Jak dla mnie uczciwa firma udzielająca chwilówek.`,
    Name: 'Marta',
    City: 'Warszawa',
    Rating: 5,
  },
  {
    // tslint:disable-next-line:max-line-length
    Text: `Nie wymagają żadnych zaświadczeń tak jak inne firmy, a kasa bardzo szybko znalazła się na moim koncie. Jak dla mnie uczciwa firma udzielająca chwilówek.`,
    Name: 'Emil',
    City: 'Poznań',
    Rating: 5,
  },
  {
    // tslint:disable-next-line:max-line-length
    Text: `Nie wymagają żadnych zaświadczeń tak jak inne firmy, a kasa bardzo szybko znalazła się na moim koncie. Jak dla mnie uczciwa firma udzielająca chwilówek.`,
    Name: 'Jadwiga',
    City: 'Zabrze',
    Rating: 5,
  },
  {
    // tslint:disable-next-line:max-line-length
    Text: `Nie wymagają żadnych zaświadczeń tak jak inne firmy, a kasa bardzo szybko znalazła się na moim koncie. Jak dla mnie uczciwa firma udzielająca chwilówek.`,
    Name: 'Agnieszka',
    City: 'Wrocław',
    Rating: 5,
  }*/
  {
    // tslint:disable-next-line:max-line-length
    Text: `Wszystko w najlepszym porządku.  Jestem bardzo zadowolona z Rupi Finance. Biorę kolejną pożyczkę i zawsze szybko bez żadnych kłopotów, środki są na koncie w kilka minut. Na pewno będę powracała. Są godni polecania.`,
    Name: 'Irena',
    City: '',
    Rating: 5,
  }
  ,
  {
    // tslint:disable-next-line:max-line-length
    Text: `Rupi Finance jest świetną firmą pożyczkową, która nie pozostawia ludzi w potrzebie samemu sobie - dla nich każdy klient jest ważny i o niego zabiegają. Polecam.`,
    Name: 'Kamil',
    City: '',
    Rating: 5,
  }
  ,
  {
    // tslint:disable-next-line:max-line-length
    Text: `Moim zdaniem jest to najlepsza z najlepszych ofert pożyczkowych w całym internecie.`,
    Name: 'Kalina',
    City: '',
    Rating: 5,
  }
  ,
  {
    // tslint:disable-next-line:max-line-length
    Text: `Rupi Finance działa bardzo szybko- zostałem zapewniony, że pieniądze będą na koncie w ciągu 15 minut od otrzymania pozytywnej decyzji i tak się stało. Super, że wszystko odbywa się tak jak w zapewnieniach. Niech inni pożyczkodawcy biorą z nich przykład.`,
    Name: 'Tadeusz',
    City: '',
    Rating: 5,
  }
  ,
  {
    // tslint:disable-next-line:max-line-length
    Text: `Firma rewelacja!  Po pierwsze - strona przejrzysta i czytelna, co ważne - mamy możliwość dowiedzenia się jakie są koszty kolejnych pożyczek, a nie tylko pierwszej. Ceny pożyczek nie zwalają z nóg! W razie wątpliwości - możemy skontaktować się z biurem obsługi klienta, które pomoże nam rozwiązać każdy nasz problem.`,
    Name: 'Konrad',
    City: '',
    Rating: 5,
  }
  ,
  {
    // tslint:disable-next-line:max-line-length
    Text: `Wzięłam kolejną pożyczkę i znowu wszystko szybko sprawnie. Nie obcinają kwoty jak w innych firmach. Serdecznie polecam!`,
    Name: 'Anna',
    City: '',
    Rating: 5,
  }
  ,
  {
    // tslint:disable-next-line:max-line-length
    Text: `Rupi Finance ma bardzo dobrą ofertę cenową, do tego wszystko odbywa się bardzo szybko. Moim zdaniem firma jest SUPER.`,
    Name: 'Olek',
    City: '',
    Rating: 5,
  }
  ,
  {
    // tslint:disable-next-line:max-line-length
    Text: `Jak dla mnie rewelacja. Oferta w Rupi jest bardzo uczciwa i godna polecenia.`,
    Name: 'Natalia',
    City: '',
    Rating: 5,
  }
];

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private apiUrl = environment.adminApiUrl;

  PromoCode$: Observable<boolean>;
  private boolPromoCode: Subject<boolean>;
  

  constructor(private http: HttpClient,
              private clipboardService: ClipboardService) {
    this.clipboardService.configure({ cleanUpAfterCopy: true });

    this.boolPromoCode = new Subject<boolean>();
    this.PromoCode$ = this.boolPromoCode.asObservable();
  }


  public SetPromoCode(newValue) {
    this.boolPromoCode.next(newValue);
  }

  public getData<T>(url) {
    if (url === '/api/getcomments') {
      return of(mockComments);
    }
    if (url === '/admin/getcontacts') {
      return of([
        {
          Phones: [environment.PHONE, environment.windykacja_phone],
          Emails: [environment.EMAIL, environment.windykacja_email],
        },
      ]);
    }

    if (url === '/api/account-menu') {
      return of([]);
    }

    return this.http.get<T>(this.apiUrl + url);
  }

  public updateData(url: string, parameters: any = {}): Observable<any> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const options = {headers};
    return this.http.put(url, parameters, options);
  }

  public copyToClipBoard(data: any) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = data;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    this.clipboardService.copyFromContent(data);
    document.body.removeChild(selBox);
  }
}
