import {Action} from '@ngrx/store';
import {FormularDoc} from '../state/info.state';
import { ModalTypes } from 'src/app/enums';

export enum InfoActionTypes {
  LoadContacts = '[Info] Load Contacts',
  SetContacts = '[Info] Set Contacts',
  LoadConditions = '[Info] Load Conditions',
  SetConditions = '[Info] Set Conditions',
  LoadMainPageInfo = '[Info] Load main page data',
  SetMainPageInfo = '[Info] Set Main Page info',
  LoadRefJobs = '[Info] Load reference jobs',
  SetRefJobs = '[Info] Set reference jobs',
  LoadRefBanks = '[Info] Load reference banks',
  LoadRefBanksSuccess = '[Info] Load reference banks success',
  SetRefBanks = '[Info] Set reference banks',
  LoadCurrentDate = '[Info] Load current datetime',
  SetCurrentDate = '[Info] Set current datetime',
  SetCalculatorAmount = '[Info] Set calculator amount',
  SetCalculatorTerm = '[Info] Set calculator term',
  SetCalculatorCommission = '[Info] Set calculator commission',
  UploadDocument = '[Info] Upload Document',
  ConfirmIncomeDocuments = '[Info] Confirm Income Documents',
  SetAcceptanceText = '[Info] Set acceptance text',
  LoadDocs = '[Info] Load docs',
  SetDocs = '[Info] Set docs',
  LoadInfoError = '[Info] Load Error',
  SetCommonErrorMessage = '[Info] Set common error message',
  SetCommonSuccessMessage = '[Info] Set common success message',
  SetCommonSuccessRedirect = '[Info] Set common success redirect',
  SetCalculatorLoadStatus = '[Info] Set Calculator Load Status',
  SetFormularDoc = '[Info] Set formular doc',
  SetContentPage = '[Info] Set content page',
}

export class LoadContacts implements Action {
  readonly type = InfoActionTypes.LoadContacts;
}

export class SetContacts implements Action {
  readonly type = InfoActionTypes.SetContacts;

  constructor(readonly payload: { contacts: any }) {
  }
}

export class LoadMainPageInfo implements Action {
  readonly type = InfoActionTypes.LoadMainPageInfo;
}

export class SetMainPageInfo implements Action {
  readonly type = InfoActionTypes.SetMainPageInfo;

  constructor(readonly payload: { mainPageData: any }) {
  }
}

export class LoadConditions implements Action {
  readonly type = InfoActionTypes.LoadConditions;

  constructor(readonly payload?: {type?: string}) {
  }
}

export class SetConditions implements Action {
  readonly type = InfoActionTypes.SetConditions;

  constructor(readonly payload: {conditions: any[]}) {
  }
}

export class SetCalculatorLoadStatus implements Action {
  readonly type = InfoActionTypes.SetCalculatorLoadStatus;

  constructor(readonly status: boolean) {
  }
}

export class LoadRefJobs implements Action {
  readonly type = InfoActionTypes.LoadRefJobs;
}

export class SetRefJobs implements Action {
  readonly type = InfoActionTypes.SetRefJobs;

  constructor(readonly payload: {
    ranges: any[],
    occupations: any[],
    sources: any[],
  }) {
  }
}

export class LoadRefBanks implements Action {
  readonly type = InfoActionTypes.LoadRefBanks;

  constructor(readonly payload?: {
    loanNumber?: string | number,
    amount?: string,
    bankListType?: number
  }) {
  }
}

export class LoadRefBanksSuccess implements Action {
  readonly type = InfoActionTypes.LoadRefBanksSuccess;
}

export class SetRefBanks implements Action {
  readonly type = InfoActionTypes.SetRefBanks;

  constructor(readonly payload: {banks: any[]}) {
  }
}

export class SetCalculatorAmount implements Action {
  readonly type = InfoActionTypes.SetCalculatorAmount;

  constructor(readonly payload: number) {
  }
}

export class SetCalculatorTerm implements Action {
  readonly type = InfoActionTypes.SetCalculatorTerm;

  constructor(readonly term: number, readonly maxTerm?: number,
              readonly minTerm?: number, readonly amountThreshold?: number, readonly maxAmount?: number,
              readonly minAmount?: number, readonly error?: string, readonly isFirstLoan?: boolean) {
  }
}


export class ConfirmIncomeDocuments implements Action {
  readonly type = InfoActionTypes.ConfirmIncomeDocuments;

  constructor() {}
}


export class UploadDocument implements Action {
  readonly type = InfoActionTypes.UploadDocument;

  constructor(readonly data: string | ArrayBuffer, readonly title: string,
              readonly mimeType: string) {
  }
}


export class SetCalculatorCommission implements Action {
  readonly type = InfoActionTypes.SetCalculatorCommission;

  constructor(readonly payload: number) {
  }
}

export class LoadCurrentDate implements Action {
  readonly type = InfoActionTypes.LoadCurrentDate;
}

export class SetCurrentDate implements Action {
  readonly type = InfoActionTypes.SetCurrentDate;

  constructor(readonly payload: string) {
  }
}

export class LoadInfoError implements Action {
  readonly type = InfoActionTypes.LoadInfoError;

  constructor(readonly payload: { loadInfoError: any }) {
  }
}

export class SetAcceptanceText implements Action {
  readonly type = InfoActionTypes.SetAcceptanceText;

  constructor(readonly text: string) {
  }
}

export class LoadDocs implements Action {
  readonly type = InfoActionTypes.LoadDocs;

  constructor(readonly loanNumber: string) {
  }
}

export class SetDocs implements Action {
  readonly type = InfoActionTypes.SetDocs;

  constructor(readonly payload: {loanNumber: any, docs: any[]}) {
  }
}

export class SetCommonErrorMessage implements Action {
  readonly type = InfoActionTypes.SetCommonErrorMessage;

  constructor(readonly payload: {errMessage: string}) {
  }
}

export class SetCommonSuccessMessage implements Action {
  readonly type = InfoActionTypes.SetCommonSuccessMessage;

  constructor(readonly payload: {successMessage: string}) {
  }
}

export class SetCommonSuccessRedirect implements Action {
  readonly type = InfoActionTypes.SetCommonSuccessRedirect;

  constructor(readonly payload: {page: string}) {
  }
}

export class SetFormularDoc implements Action {
  readonly type = InfoActionTypes.SetFormularDoc;

  constructor(readonly payload: {formularDoc: FormularDoc, parent: ModalTypes} ) {
  }
}

export class SetContentPage implements Action {
  readonly type = InfoActionTypes.SetContentPage;

  constructor(readonly payload: {page: string, parent: ModalTypes} ) {
  }
}


export type InfoActions = LoadContacts |
  SetContacts |
  LoadMainPageInfo |
  SetMainPageInfo |
  LoadConditions |
  SetConditions |
  LoadRefBanks |
  SetRefBanks |
  LoadRefBanksSuccess |
  LoadRefJobs |
  SetRefJobs |
  LoadCurrentDate |
  SetCurrentDate |
  SetCalculatorAmount |
  SetCalculatorTerm |
  SetCalculatorCommission |
  SetAcceptanceText |
  LoadDocs |
  SetDocs |
  UploadDocument |
  ConfirmIncomeDocuments |
  LoadInfoError |
  SetCommonErrorMessage |
  SetCommonSuccessMessage |
  SetCommonSuccessRedirect |
  SetCalculatorLoadStatus |
  SetFormularDoc |
  SetContentPage;
