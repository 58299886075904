import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-information-clause-content',
  templateUrl: './information-clause-content.component.html',
  styleUrls: ['./information-clause-content.component.css']
})
export class InformationClauseContentComponent implements OnInit {

  public ADDRESS: string;
  public EMAIL: string;
  public EMAIL_IOD: string;

  constructor() { 
    this.ADDRESS = environment.ADDRESS;
    this.EMAIL = environment.EMAIL;
    this.EMAIL_IOD = environment.EMAIL_IOD;
  }

  ngOnInit() {
  }

}
