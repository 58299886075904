import {Component, HostListener, Input, OnInit} from '@angular/core';
import {BankModel} from '../../../static-pages/models/BankModel';
import {Observable} from 'rxjs';
import {selectExactBank, selectUser, selectBankConfirmationsStatus, selectLoanCurrentInfo, selectBanks} from '../../../../store/selectors';
import {AppState} from '../../../../store/state';
import {Store} from '@ngrx/store';
import {ApiService, DataService} from '../../../../services';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { OpenModal, SetCommonErrorMessage } from 'src/app/store/actions';
import { ModalTypes } from 'src/app/enums';

@Component({
  selector: 'app-banks-selector',
  templateUrl: './banks-selector.component.html',
  styleUrls: ['./banks-selector.component.scss']
})
export class BanksSelectorComponent implements OnInit {

  constructor(private store: Store<AppState>,
              private apiService: ApiService,
             
              private dataService: DataService) {
  }

  @Input()
  public spinner: NgxSpinnerService;
  @Input()
  public showAllDestinations: boolean;
  @Input()
  public buttonBlock: boolean;
  @Input()
  public withAddress: boolean;
  @Input()
  public title = 'Wybierz swój bank';
  @Input()
  public hideTitle: boolean;
  @Input()
  public banks: BankModel[];
  @Input()
  public fullSizeSelect: boolean;
  @Input()
  public kwota: number;
  @Input()
  public showBtn: boolean;
  @Input()
  public hideBM: boolean;
  @Input()
  public acceptanceOfTerms: boolean;
  @Input()
  public loanNumber: string;
  @Input()
  public identityCode: string;
  @Input()
  public ChangeDisabledIfSelected: boolean;

  public selectedBank$: Observable<BankModel>;
  public bankDDActive: boolean;
  public bankMessage = `Spłata pożyczki nr [wpisz numer pożyczki]*`;
  public selectedBankUrl: string;
  private userId: number;
  private blueMediaRequestUrl: string;
  private blueMediaCommonUrl: string;
  public banks$: Observable<BankModel[]>;

  private  bankConfirmed$: Observable<boolean>;

  @HostListener('click', ['$event'])
  public onClick(event) {
    const target = event.target as HTMLElement;
    if (!target.classList.contains('toggler')) {
      this.bankDDActive = false;
    }
  }

  ngOnInit() {

    this.blueMediaRequestUrl = environment.blueMediaRequestUrl;
    this.blueMediaCommonUrl = environment.blueMediaCommonUrl

    this.selectedBank$ = this.store.select(selectExactBank, {bankName: ''});
    this.selectedBank$.subscribe((data: any) => {
      if (data) {
        this.selectedBankUrl = data.url;
      }
    });
    

    if (this.ChangeDisabledIfSelected)
    {
      this.banks$ = this.store.select(selectBanks);

      this.banks$.subscribe((data: any) => {
        if (data) {
          data.forEach(element => {
            if (element.defaultBank && element.name !== "Inny" && element.name !== "Blue Media")
              this.onBankSelect(element.name);
          });
        }
      });
    }




    this.store.select(selectUser)
      .subscribe(user => {
        if (user && user.PersonalID) {
          this.userId = user.PersonalID;
        }
      });

      this.store.select(selectBankConfirmationsStatus)
        .subscribe(status => {
          if (!status) {
            this.hideBM = true;
          }
      });
  }

  public onBankSelect(bankName: string) {
    this.selectedBank$ = this.store.select(selectExactBank, {bankName});
    this.selectedBank$.subscribe((data: any) => this.selectedBankUrl = data.url);
    this.toggleDropdown();
  }

  public copyToClipBoard(data: any, thisobject) {

    this.dataService.copyToClipBoard(data);

    $(thisobject).addClass('active');
    setTimeout(() => $(thisobject).removeClass('active'), 100);

  }

  public toggleDropdown() {
    this.bankDDActive = !this.bankDDActive;
  }

  public openNewLink(thisobject) {

    $(thisobject).addClass('active');
    setTimeout(() => $(thisobject).removeClass('active'), 100);

    if (!this.selectedBankUrl) {

      this.store.select(selectBankConfirmationsStatus)
      .subscribe(status => {


/*




        if (status) {
          window.open(`${environment.blueMediaUrl}Transaction/Repaymant?clientID=${this.userId}&amount=${this.kwota}`);
        }
        
         else {
          window.open(`${environment.blueMediaUrl}Transaction/Verification?clientID=${this.userId}`);
        }
        */

      });

    } else {
      window.open('http://' + this.selectedBankUrl, '_blank');
    }
  }

}
