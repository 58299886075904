import {ExtensionLoanTypes} from '../../enums/ExtensionLoanTypes';

export interface LoanInfo {
  loanState?: any;
  loanSchedule?: any[];
  loanHistory?: any[];
  loanHistoryLoaded?: boolean;
  refinanceOptions?: any;
  currentInfo?: any;
  error?: any;
  loanRequestError?: any;
  loanRequestSuccess?: boolean;
  loanSmsVerified?: boolean;
  loanSmsError?: any;
  formSubmitted?: boolean;
  refinanceRequestSuccess?: boolean;
  refinanceRequestError?: any;
  currentRefinanceOptionsIndex?: number;
  confirmationLoanSmsRequestedStatus?: boolean;
  uploadedDocuments?: any;
}

export interface UserState {

  fullUserData?: UserDataModel;
  PersonalID?: number;
  actualAddress?: string | null;
  bankAccount?: string;
  confirmedBankAccount?: boolean;
  closedLoansCount?: number;
  closedLoansCountForFreeLoan?: number;
  creditLimit?: any | null;
  declaredAddress?: string | null;
  discountPercentage?: number;
  blockNewLoanMessage?: string | null;
  email?: string;
  emailMarketingAccept?: boolean;
  phoneMarketingAccept?: boolean;
  fullName?: string | null;
  iC_Number?: string | null;
  id?: number;
  isInBlackList?: boolean;
  loyaltyPoints?: number;
  identityCode?: string | null;
  phoneNumber?: string | null;
  refinanceAccepted?: boolean;
  firstName?: string;
  lastName?: string;
  notifications?: any[];
  IsManualVerificationPending?: boolean;

  loanInfo?: LoanInfo;
  editorPopup?: number | null;
  changingState?: number;
  emailChangingMessage?: string;
  phoneChangingMessage?: string;
  acceptanceText?: string;
  receiveNews?: boolean;
  marketingAccepted?: boolean;
  temporaryBlocked?: boolean;
  messageToClient?: string;
  loanVerificationRequested?: boolean;
  marketingAcceptedPartner?: boolean;
  extensionLoanType?: ExtensionLoanTypes;
  updateExpenses?: boolean;
  updateIncome?: boolean;
  showDiscountRegulations?: boolean;
  confirmedIncomeExpenses?: boolean;
}

export const initialUserState = {
  loanInfo: {
    currentInfo: {}
  }
};

export interface UserDataModel {
  PersonalID?: number;
  firstName?: string;
  lastName?: string;
  identityCode?: string;
  phone?: string;
  eMail?: string;
  employer?: string;
  position?: string;
  marketingAccepted?: boolean;
  receiveNews?: boolean;
  registrationComplete?: boolean;
  confirmedBankAccount?: boolean;
  bankAccount?: string;
  declaredResidence?: AddressModel;
  actualResidence?: AddressModel;
  acceptTerms?: boolean;
  setPassword?: boolean;
  income?: number;
  expenses?: number;
  confirmedIncomeExpenses?: boolean;
}

export interface AddressModel {
  city?: string;
  street?: string;
  house?: string;
  apartment?: string;
  postalCode?: string;
}
