// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  apiUrl: 'https://alfaservice.itis.lv:7443/api/',
  blueMediaRequestUrl: 'https://pay-accept.bm.pl/payment',
  blueMediaCommonUrl: 'https://rupibm.itis.lv',
  adminApiUrl: '',
  AppID: 'ffd0ff3c-3b0d-4f42-9375-54f7cf91fa21',
  tokenExpireTime: 1800000,// 1800000 // 1000 (sec)   1800000
  konkurs_email: 'konkurs@rupi.pl',
  windykacja_email: 'windykacja@rupi.pl',
  windykacja_phone: '22 100 44 99',
  sekretariat_email: 'sekretariat@rupi.pl',
  KRS: '0000768852',
  NIP: '5272880319',
  REGON: '382409240',
  ADDRESS: 'al. Jana Pawła II 27, 00-867 Warszawa',
  PHONE: '22 100 66 33',
  EMAIL: 'info@rupi.pl',
  URL: 'www.rupi.pl',
  EMAIL_IOD: 'iod@rupi.pl',
  transactionLinkUrl: 'https://widget.sandbox.transactionlink.io/transactionlink-widget.umd.js',
  kontomatikClient:  'alfakredyt-test'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
