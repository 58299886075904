import {Component, OnInit, Renderer2, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import {AppState} from '../../../../../store/state';
import {Store} from '@ngrx/store';
import {selectFormularDoc} from '../../../../../store/selectors';
import saveAs from 'file-saver';
import * as printJS from 'print-js';
// import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-formular-modal',
  templateUrl: './formular-modal.component.html',
  styleUrls: ['./formular-modal.component.scss']
})
export class FormularModalComponent implements OnInit, AfterViewInit {
  // public pdf: SafeResourceUrl;
  public pdfName: string;
  private pdfSource: string;
  public pdf: string;
  public height = '100%';

  constructor(private store: Store<AppState>,
              // private sanitizer: DomSanitizer,
              private renderer: Renderer2,
              private cdref: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.store.select(selectFormularDoc)
      .subscribe(doc => {
        if (doc) {
          // this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + doc.data);
          this.pdf = doc.data;
          this.pdfSource = doc.data;
          this.pdfName = doc.title;
        }
      });
  }

  ngAfterViewInit() {
    this.renderer.setStyle(document.getElementsByClassName('small_popap')[0], 'width', '100%');
    this.renderer.setStyle(document.getElementsByClassName('small_popap')[0], 'max-width', '1000px');
    this.renderer.setStyle(document.getElementsByClassName('small_popap')[0], 'height', '100%');
    this.renderer.setStyle(document.getElementsByClassName('small_popap')[0], 'max-height', (window.innerHeight * .8) + 'px');
    this.renderer.setStyle(document.getElementsByClassName('popup__content')[0], 'height', '100%');
    this.height = (document.getElementsByClassName('popup__content')[0].clientHeight - 133) + 'px';
    this.cdref.detectChanges();
  }

  public saveFile() {
    saveAs(this.convertToArrayBuffer(this.pdfSource), `${this.pdfName}`);
  }

// {printable: base64, type: 'pdf', base64: true}

  public printFile() {
    printJS({  printable: this.pdfSource, type: 'pdf', showModal: true,
      base64: true });
  }

  private convertToArrayBuffer(base64String: string) {
    const binaryString = atob(base64String);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], {type: 'application/pdf'});

    // return bytes.buffer;

    // const blob = new Blob([bytes], {type: 'application/pdf'});
    // const blob = new Blob([array], { type: 'application/pdf' });
    // return URL.createObjectURL(blob);
  }

}
