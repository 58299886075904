import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-help-panel',
  templateUrl: './help-panel.component.html',
  styleUrls: ['./help-panel.component.scss']
})
export class HelpPanelComponent implements OnInit {
  private lastSelectedId;
  public PHONE: string;
  public EMAIL: string;

  constructor() {
    this.PHONE = environment.PHONE;
    this.EMAIL = environment.EMAIL;
  }

  ngOnInit() {
    const contactsHelperBar = document.querySelector('.contacts-helper .buttons');
    $(contactsHelperBar).on('click', (e) => this._handleHelperContactsBar(e));

    $('.mobile-chat-helper').on('click', (e) => this._handleHelperContactsBar(e));
  }

  private _handleHelperContactsBar(e) {

    let dataId = e.target.dataset.id;
    let newTarget = false;
    if (!dataId) {
      dataId = e.target.parentNode.dataset.id;
      newTarget = true;
    }
    if (dataId) {
      const activeButtons = document.querySelectorAll('.helper-tab-button.active');
      const activeTabs = document.querySelectorAll('.helper-tab-content-holder .active');
      const neededTab = document.getElementById(dataId);

      activeButtons.forEach(el => el.classList.remove('active'));
      activeTabs.forEach(el => el.classList.remove('active'));

      newTarget ? e.target.parentNode.classList.add('active') : e.target.classList.add('active');
      neededTab.classList.add('active');
      // @ts-ignore
      neededTab.parentNode.classList.add('active');

      if (this.lastSelectedId === dataId) {
        newTarget ? e.target.parentNode.classList.remove('active') : e.target.classList.remove('active');
        e.target.classList.remove('active');
        neededTab.classList.remove('active');
        // @ts-ignore
        neededTab.parentNode.classList.remove('active');
        this.lastSelectedId = '';
        return;
      }
      this.lastSelectedId = dataId;
    }
  }
}
